import React from 'react'
import PagePreview from './page-preview'

import styles from './page-preview-list.module.css'

function PagePreviewGrid (props) {
  return (
    <div className={styles.root}>
      <ul className={styles.grid}>
        {props.nodes &&
          props.nodes.map(node => (
            <li key={node.id}>
              <PagePreview {...node} isInList />
            </li>
          ))}
      </ul>
    </div>
  )
}

PagePreviewGrid.defaultProps = {
  title: '',
  nodes: []
}

export default PagePreviewGrid
