import React from 'react'

import styles from './hero.module.css'

function Hero () {
    return (
        <div className={styles.hero}>
            <hr/>
            <div className={styles.text}>
                <div className={styles.heroTitle}>
                    <p>Linha do tempo das <span>Ganhadeiras</span></p>
                </div>
                <div className={styles.heroDesc}>
                    <p>
                        A história de um lugar, Itapuã, mas também a história de um país em 
                        transformação pelo protagonismo das mulheres. Uma história em que a luta 
                        pela sobrevivência se converte em poesia, música, dança, aconchego e afeto. 
                    </p>
                </div>
            </div>
        </div>
    )
}
  
export default Hero